<template>
	<div class="Merchant">
		<w-navTab titleText="客户订单列表"></w-navTab>
		<div class="Merchant-body">
			<div class='head-nav'>
				<div :class="[{ 'head-nav-active': active == item.id }]" @click="onChange(item)"
					v-for="(item, key) in navList">
					{{ item.title }}
				</div>
			</div>
			<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
				<div class="container-goods" v-for="item in orderList"
					@click="$router.push({ name: 'MerchantDetail',params:{id: item.id, merchantId: id}})">
					<div class="container-goods-top">
						<div class="container-goods-top-left">{{item.create_time}}</div>
						<div class="container-goods-top-right status-color1" v-if="item.order_status == 2">待自提</div>
						<div class="container-goods-top-right status-color2" v-if="item.order_status == 3">待发货</div>
						<div class="container-goods-top-right status-color3" v-if="item.order_status == 4">待收货</div>
						<div class="container-goods-top-right status-color4" v-if="item.order_status == 5">已完成</div>
					</div>
					<div class="container-goods-item" v-for="vo in item.products">
						<div class="container-goods-picture">
							<img :src="vo.thumb" alt="">
						</div>
						<div class="container-goods-box">
							<div class="container-goods-box-title">{{vo.title}}</div>
							<div class="container-goods-box-bottom">
								<div class="container-goods-box-bottom-number">
									数量：{{vo.product_num}}
								</div>
							</div>
						</div>
					</div>
					<div class="container-goods-bottom">
						<div class="container-goods-bottom-title">
							<div class="container-goods-bottom-title-right">
								<div class="title"><span>￥</span>{{item.practical_amount}}</div>
								<div class="text">积分抵扣<span>{{item.total_deduction_amount}}</span></div>
							</div>
							<div class="container-goods-bottom-title-left">详情<van-icon name="arrow" color="#000" />
							</div>
						</div>
					</div>
				</div>
			</VantList>
		</div>

	</div>
</template>

<script>
	import VantVendor from '@/vendor/vant';
	import VantList from '@/components/VantList';
	import Merchant from '@/api/merchant';
	export default {
		name: 'Merchant',
		data() {
			return {
				id: '',
				active: 0,
				navList: [{
						id: 0,
						title: '全部订单'
					},
					{
						id: 2,
						title: '待自提'
					},
					{
						id: 3,
						title: '待发货'
					},
					{
						id: 4,
						title: '待收货'
					},
					{
						id: 5,
						title: '已完成'
					}
				],
				page: 1,
				limit: 10,
				loading: true,
				finished: false,
				isEmpty: false,
				orderList: []
			};
		},
		created() {
			this.id = this.$route.params.id;
			this.onLoad()
		},
		methods: {
			onChange(item) {
				this.active = item.id;
				this.page = 1
				this.finished = false
				this.isEmpty = false
				this.orderList = []
				this.onLoad()
			},
			onLoad() {
				this.loading = true;
				let params = {
					page: this.page,
					limit: this.limit,
					order_status: this.active,
					merchant_id: this.id
				};
				Merchant.productMerchantOrder(params)
					.then(result => {
						this.page++;

						this.orderList = this.orderList.concat(result.data.lists);

						this.loading = false;

						if (this.orderList.length <= 0) {
							this.isEmpty = true;
							this.finished = true
						}

						if (this.orderList.length >= result.data.total) {
							this.finished = true;
						}
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
		},
		components: {
			VantList,
		}
	};
</script>

<style lang="less" scoped>
	.Merchant {
		width: 100%;
		min-height: 100vh;
		background: #f2f2f2;

		.Merchant-body {
			padding: 10px;
			box-sizing: border-box;

			.head-nav {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-top: 2px;
				border-bottom: 1px solid #ddd;
				margin-bottom: 10px;

				.color {
					font-weight: bold;
				}

				div {
					font-size: 16px;
					line-height: 23px;
					color: #000;
					padding-bottom: 12px;
					margin-bottom: -1px;
					box-sizing: border-box;
				}
			}

			.head-nav-active {
				padding-bottom: 10px !important;
				color: #3377ff !important;
				border-bottom: 2px solid #3377ff !important;
				box-sizing: border-box;
			}

			.container-goods:first-child {
				margin-top: 0;
			}

			.container-goods {
				padding: 12px;
				background: #FFF;
				border-radius: 8px;
				margin-top: 10px;

				.container-goods-top {
					border-bottom: 1px solid #F2F2F2;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 8px;

					.container-goods-top-left {
						font-size: 14px;
						line-height: 21px;
						color: #777;
					}

					.container-goods-top-right {
						font-size: 14px;
						line-height: 21px;
						padding: 0 6px;
						border-radius: 2px;
						text-align: center;
						box-sizing: border-box;
					}
					.status-color1{
						color: #FFB23F;
						background-color: rgba(255, 178, 63, 0.1);
					}
					.status-color2{
						color: #3377FF;
						background-color: rgba(51, 119, 255, 0.1);
					}
					.status-color3{
						color: #6ABB1B;
						background-color: rgba(106, 187, 27, 0.1);
					}
					.status-color4{
						color: #aaa;
						background-color: #F2F2F2;
					}
				}

				.container-goods-item:first-child {
					padding-top: 0;
				}

				.container-goods-item {
					padding: 16px 0;
					border-bottom: 1px solid #F2F2F2;
					display: flex;

					.container-goods-picture {
						width: 80px;
						height: 80px;
						min-width: 80px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-radius: 6px;
						}
					}

					.container-goods-box {
						padding-left: 10px;

						.container-goods-box-title {
							width: 241px;
							font-size: 16px;
							line-height: 22px;
							color: #000;
							height: 44px;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
						}

						.container-goods-box-bottom {
							display: flex;
							justify-content: space-between;
							align-items: center;
							margin-top: 14px;

							.container-goods-box-bottom-price {
								font-size: 22px;
								line-height: 22px;
								color: #F9615D;

								span {
									font-size: 16px;
									line-height: 16px;
								}
							}

							.container-goods-box-bottom-number {
								font-size: 16px;
								line-height: 22px;
								color: #777;
							}
						}
					}

				}

				.container-goods-bottom {
					.container-goods-bottom-title {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-top: 16px;

						.container-goods-bottom-title-left {
							font-size: 14px;
							line-height: 17px;
							color: #666;
						}

						.container-goods-bottom-title-right {
							.title {
								font-size: 22px;
								line-height: 22px;
								color: #151C39;

								span {
									font-size: 16px;
									line-height: 16px;
								}
							}

							.text {
								font-size: 14px;
								line-height: 21px;
								color: #666;
								display: flex;
								align-items: center;

								span {
									display: block;
									display: flex;
									font-size: 14px;
									line-height: 17px;
									color: #151C39;

									&:before {
										content: '￥';
										font-size: 10px;
										line-height: 17px;
									}
								}
							}
						}
					}
				}
			}
		}

	}
</style>